import 'intersection-observer';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.values';
import 'core-js/modules/es.array.fill';
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.find-index';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.starts-with';
import 'core-js/modules/es.string.split';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
